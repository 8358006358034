import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import AdminLayout from './../layout/AdminLayout';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Api from './../../components/Api';

const Edit = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState('');
    const [url, setUrl] = useState('');
    const { id } = useParams();

    // setCode(123);
    // // 提交表单时的回调函数
    const onFinish = (values) => {
        console.log('Form values:', values);
    };
    const codeChange = (event) => {
        setCode(event.target.value);
    };
    const titleChange = (event) => {
        setTitle(event.target.value);
    };
    const countryChange = (event) => {
        setCountry(event.target.value);
    };
    const urlChange = (event) => {
        setUrl(event.target.value);
    };

    useEffect(() => {
        getApk();
    }, []);

    const getApk = () => {
        if (id > 0) {
            Api.apk()
                .then((response) => {
                    if (response.data.code > 0) {
                        if (response.data.code === 10003) {
                            navigate('/login');
                        }
                    } else {
                        // console.log(response.data.data.code);
                        setCode(response.data.data.code);
                        setTitle(response.data.data.title);
                        setCountry(response.data.data.country);
                        setUrl(response.data.data.url);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            navigate('/apk');
        }
    };

    const edit = () => {
        if (url === '') {
            Modal.info({ title: '提示', content: 'url不能为空' });
            return false;
        }
        axios
            .post('http://34.124.249.254/api/apk/' + id, {
                code: code,
                title: title,
                country: country,
                url: url,
            })
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    navigate('/apk');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const back = () => {
        navigate('/apk');
    };

    return (
        <AdminLayout>
            <h1>新增监控包</h1>
            <Form form={form} onFinish={onFinish}>
                <Form.Item label="编号">
                    <Input value={code} onChange={codeChange} />
                </Form.Item>
                <Form.Item label="标题">
                    <Input value={title} onChange={titleChange} />
                </Form.Item>
                <Form.Item label="国家">
                    <Input value={country} onChange={countryChange} />
                </Form.Item>
                <Form.Item
                    label="网址"
                    rules={[
                        {
                            required: true,
                            message: '网址不能为空',
                        },
                    ]}
                >
                    <Input value={url} onChange={urlChange} />
                </Form.Item>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button type="primary" onClick={edit}>
                        提交
                    </Button>
                    <Button type="" onClick={back}>
                        返回
                    </Button>
                </div>
            </Form>
        </AdminLayout>
    );
};

export default Edit;
