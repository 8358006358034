import React, { useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import AdminLayout from './../layout/AdminLayout';
import { useNavigate } from 'react-router-dom';
import Api from './../../components/Api';

const Add = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState('');
    const [url, setUrl] = useState('');

    const codeChange = (event) => {
        setCode(event.target.value);
    };
    const titleChange = (event) => {
        setTitle(event.target.value);
    };
    const countryChange = (event) => {
        setCountry(event.target.value);
    };
    const urlChange = (event) => {
        setUrl(event.target.value);
    };

    const add = () => {
        if (url === '') {
            Modal.info({ title: '提示', content: 'url不能为空' });
            return false;
        }
        Api.apkAdd({ code: code, title: title, country: country, url: url })
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    navigate('/apk');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const back = () => {
        navigate('/apk');
    };

    return (
        <AdminLayout>
            <h1>新增监控包</h1>
            <Form form={form}>
                <Form.Item name="code" label="编号">
                    <Input value={code} onChange={codeChange} />
                </Form.Item>
                <Form.Item name="title" label="标题">
                    <Input value={title} onChange={titleChange} />
                </Form.Item>
                <Form.Item name="country" label="国家">
                    <Input value={country} onChange={countryChange} />
                </Form.Item>
                <Form.Item
                    name="url"
                    label="网址"
                    rules={[
                        {
                            required: true,
                            message: '网址不能为空',
                        },
                    ]}
                >
                    <Input
                        addonBefore="https://play.google.com/store/apps/details?id="
                        value={url}
                        onChange={urlChange}
                    />
                </Form.Item>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button type="primary" onClick={add}>
                        提交
                    </Button>
                    <Button type="" onClick={back}>
                        返回
                    </Button>
                </div>
            </Form>
        </AdminLayout>
    );
};

export default Add;
