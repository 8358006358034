import React, { useMemo } from 'react';
import { Layout, Menu, Avatar, Typography, Space } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    DashboardOutlined,
    PullRequestOutlined,
    FileZipOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { clearAuthToken } from 'components/Request';
const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const logout = () => {
        clearAuthToken();
        navigate('/login');
    };

    const selectedKeys = useMemo(() => {
        return [location.pathname];
    }, [location]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider>
                <Typography.Title
                    level={4}
                    style={{
                        color: '#fff',
                        padding: 15,
                    }}
                >
                    Admin Monitor
                </Typography.Title>
                <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
                    <Menu.Item key="/group" icon={<DashboardOutlined />}>
                        <Link to="/group">监控组列表</Link>
                    </Menu.Item>
                    <Menu.Item key="/redirect" icon={<PullRequestOutlined />}>
                        <Link to="/redirect">转发列表</Link>
                    </Menu.Item>
                    <Menu.Item
                        key="/config/apk-list"
                        icon={<FileZipOutlined />}
                    >
                        <Link to="/config/apk-list">APK包管理</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{ textAlign: 'right' }}
                >
                    <Space>
                        <Avatar icon={<UserOutlined />} />
                        <Typography.Link onClick={logout}>
                            退出登录
                        </Typography.Link>
                    </Space>
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <div
                        style={{
                            padding: 24,
                            background: '#fff',
                            minHeight: 360,
                        }}
                    >
                        {/* 内容区域 */}
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Ant Design ©2023 Created by Eamon
                </Footer>
            </Layout>
        </Layout>
    );
};

export default AdminLayout;
