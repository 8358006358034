import { miniGameRequest } from './Request';

interface CommonRes<IData> {
    code: number;
    message: string;
    currentTime: number;
    data: IData;
}

export default {
    getPkgList: function (params: {
        page?: number;
        limit?: number;
        pkg_name?: string;
        bundle?: string;
    }): Promise<
        CommonRes<
            {
                id: number;
                pkg_name: string;
                pkg_bundle: string;
            }[]
        >
    > {
        return miniGameRequest({
            url: '/config/get-pkg-list',
            method: 'post',
            data: params,
        });
    },

    getPkgVersionList: function (params: {
        page?: number;
        limit?: number;
        pkg_id?: number;
        pkg_name?: string;
        bundle?: string;
        version?: string;
    }): Promise<
        CommonRes<
            {
                pkg_id: number;
                pkg_name: string;
                bundle: string;
                version: string;
                channel: string;
                config: string;
            }[]
        >
    > {
        return miniGameRequest({
            url: '/config/get-pkg-version-list',
            method: 'post',
            data: params,
        });
    },

    createPkg: function (params: {
        pkg_name: string;
        bundle: string;
    }): Promise<CommonRes<Record<string, any>>> {
        return miniGameRequest({
            url: '/config/create-pkg',
            method: 'post',
            data: params,
        });
    },

    createPkgVersion: function (params: {
        pkg_id: number;
        version: string;
        channel: string;
        config: string;
    }): Promise<CommonRes<Record<string, any>>> {
        return miniGameRequest({
            url: '/config/create-pkg-version',
            method: 'post',
            data: params,
        });
    },

    updatePkgVersion: function (params: {
        id: number; // is version id
        channel: string;
        config: string;
    }): Promise<CommonRes<Record<string, any>>> {
        return miniGameRequest({
            url: '/config/update-pkg-version',
            method: 'post',
            data: params,
        });
    },
};
