import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Modal, Form, Input } from 'antd';
import AdminLayout from './../layout/AdminLayout';
import { useNavigate, useParams } from 'react-router-dom';

import Api from './../../components/Api';

const ListPage = () => {
    const [dataSource, setDataSource] = useState([]);
    const [visible, setVisible] = useState(false);
    const [redirectId, setRedirectId] = useState(0);
    const [opr, setOpr] = useState('add');
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        Api.redirectList({ page_size: 100 })
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    var redirect_list = response.data.data;
                    for (var i = 0; i < redirect_list.length; i++) {
                        if (redirect_list[i].last_monitor_time > 0) {
                            const date = new Date(
                                redirect_list[i].last_monitor_time * 1000
                            );
                            redirect_list[i].last_monitor_time =
                                date.toLocaleString();
                        } else {
                            redirect_list[i].last_monitor_time = '';
                        }
                    }
                    setDataSource(redirect_list);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const submit = () => {
        form.validateFields()
            .then((values) => {
                if (opr === 'add') {
                    Api.redirectAdd({
                        code: values.code,
                        remark: values.remark,
                        url: values.url,
                    })
                        .then((response) => {
                            if (response.data.code > 0) {
                                if (response.data.code === 10003) {
                                    navigate('/login');
                                } else {
                                    alert(response.data.msg);
                                }
                            } else {
                                setVisible(false);
                                form.resetFields();
                                getList();
                            }
                        })
                        .catch((error) => {
                            alert(error.response.data.msg);
                        });
                } else if (opr === 'edit') {
                    Api.redirectEdit(redirectId, {
                        code: values.code,
                        remark: values.remark,
                        url: values.url,
                    })
                        .then((response) => {
                            if (response.data.code > 0) {
                                if (response.data.code === 10003) {
                                    navigate('/login');
                                } else {
                                    alert(response.data.msg);
                                }
                            } else {
                                setVisible(false);
                                form.resetFields();
                                getList();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            })
            .catch((error) => {});
    };

    const del = (event) => {
        var id = event.target.dataset.id;
        Api.redirectDel(id)
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    getList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleAdd = () => {
        form.setFieldsValue({
            code: '',
            remark: '',
            url: '',
        });
        setVisible(true);
        setOpr('add');
        setRedirectId(0);

        setIsDisabled(false);
    };

    const copy = (e) => {
        copyToClipboard(
            e.target.dataset.code
        );
        // navigator.clipboard.writeText(process.env.REACT_APP_REDIRECT_BASE_URL + e.target.dataset.code);
    };

    function copyToClipboard(textToCopy) {
        // navigator clipboard 需要https等安全上下文
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // 创建text area
            let textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            // 使text area不在viewport，同时设置不可见
            textArea.style.position = 'absolute';
            textArea.style.opacity = '0';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? res(null) : rej();
                textArea.remove();
            });
        }
    }

    const getCode = (e) => {
        Api.redirectCode()
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    form.setFieldsValue({
                        code: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const handleEdit = (e) => {
        form.setFieldsValue({
            code: e.target.dataset.code,
            remark: e.target.dataset.remark,
            url: e.target.dataset.url,
        });
        setVisible(true);
        setOpr('edit');
        setRedirectId(e.target.dataset.id);
        setIsDisabled(true);
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: '短链码', dataIndex: 'code', key: 'code' },
        { title: '跳转地址', dataIndex: 'url', key: 'url' },
        { title: '备注', dataIndex: 'remark', key: 'remark' },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <button
                        type="primary"
                        onClick={copy}
                        data-code={record.code}
                        className="ant-btn ant-btn-primary"
                        ant-click-animating-without-extra-node="false"
                    >
                        复制短链
                    </button>
                    <button
                        type="primary"
                        onClick={handleEdit}
                        data-code={record.code}
                        data-url={record.url}
                        data-remark={record.remark}
                        data-id={record.id}
                        className="ant-btn ant-btn-primary"
                        ant-click-animating-without-extra-node="false"
                    >
                        修改
                    </button>
                    <button
                        type="primary"
                        onClick={del}
                        data-id={record.id}
                        className="ant-btn ant-btn-primary ant-btn-dangerous"
                    >
                        删除
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <AdminLayout>
            {/* 搜索栏 */}
            <div className="search-bar">{/* 搜索表单项... */}</div>

            {/* 新增按钮 */}
            <div
                className="add-button"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button type="primary" onClick={handleAdd}>
                    添加
                </Button>
            </div>
            <br />
            {/* 列表 */}
            <Table dataSource={dataSource} columns={columns} rowKey="id" />
            <Modal
                open={visible}
                title="转链"
                onCancel={() => setVisible(false)}
                onOk={submit}
                okText="确定"
                cancelText="取消"
            >
                <Form form={form}>
                    {opr == 'add' ? (
                        <Button onClick={getCode}>随机生成</Button>
                    ) : (
                        false
                    )}

                    <Form.Item
                        name="code"
                        label="转链码"
                        rules={[{ required: true, message: '请输入转链码' }]}
                    >
                        {opr == 'add' ? <Input /> : <Input disabled />}
                    </Form.Item>

                    <Form.Item
                        name="url"
                        label="网址"
                        rules={[{ required: true, message: '请输入网址' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label="备注"
                        rules={[{ required: true, message: '请输入备注' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </AdminLayout>
    );
};

export default ListPage;
