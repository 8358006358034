import axios from 'axios';
import {
    MINIGAME_API_URL,
    REACT_APP_API_URL,
    APK_MONITOR_TOKEN_KEY,
} from '../config/config';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
//创建axios
const request = axios.create({
    baseURL: REACT_APP_API_URL,
    // baseURL: "http://34.143.255.102/",

    timeout: 3000, //请求超时
});

request.interceptors.request.use((config) => {
    if (config.url === '/api/login') {
        return config;
    }
    const token = localStorage.getItem(APK_MONITOR_TOKEN_KEY);
    if (token === null || token?.length === 0) {
        location.href = '/login';
        return;
    }
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});
// function setAuthToken(token) {
//     console.log('🚀 ~ file: Request.js:14 ~ setAuthToken ~ token:', token);
//     request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// }

export const setAuthToken = (token: string) => {
    localStorage.setItem(APK_MONITOR_TOKEN_KEY, token);
};

export const clearAuthToken = () => {
    localStorage.removeItem(APK_MONITOR_TOKEN_KEY);
};

//创建axios
const miniGameRequest = axios.create({
    baseURL: MINIGAME_API_URL,
    timeout: 3000, //请求超时
});

miniGameRequest.interceptors.request.use((config) => {
    const token = localStorage.getItem(APK_MONITOR_TOKEN_KEY);
    if (token === null || token?.length === 0) {
        location.href = '/login';
        return;
    }
    // config.headers.Authorization = token;

    return config;
});
miniGameRequest.interceptors.response.use(
    (res) => {
        if (res.status !== 200 || !res?.data) {
            return Promise.reject(res.statusText || 'Wrong Services');
        }
        return res.data;
    },
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Wrong Services'
        )
);
export { request, miniGameRequest };
