import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import AdminLayout from './../layout/AdminLayout';
import Api from './../../components/Api';

const Add = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [name, setName] = useState('');
    const [describe, setDescribe] = useState('');

    const nameChange = (event) => {
        setName(event.target.value);
    };

    const describeChange = (event) => {
        setDescribe(event.target.value);
    };

    const add = () => {
        if (name === '') {
            Modal.info({ title: '提示', content: '名称不能为空' });
            return false;
        }

        Api.groupAdd({ name: name, describe: describe })
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    navigate('/group');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const back = () => {
        navigate('/group');
    };

    return (
        <AdminLayout>
            <h1>新增监控组</h1>
            <Form form={form}>
                <Form.Item
                    name="name"
                    label={
                        <>
                            <span style={{ color: 'red' }}>*</span>监控组名称
                        </>
                    }
                >
                    <Input
                        value={name}
                        onChange={nameChange}
                        rules={[
                            {
                                required: true,
                                message: '名称不能为空',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item name="describe" label="监控组简介">
                    <Input value={describe} onChange={describeChange} />
                </Form.Item>

                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button type="primary" onClick={add}>
                        提交
                    </Button>
                    <Button type="" onClick={back}>
                        返回
                    </Button>
                </div>
            </Form>
        </AdminLayout>
    );
};

export default Add;
