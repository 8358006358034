import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Modal, Form, Input, message } from 'antd';
import AdminLayout from '../layout/AdminLayout';
import { useNavigate, useParams } from 'react-router-dom';
import MiniGameApi from 'components/MiniGameApi';
import { LeftOutlined } from '@ant-design/icons';

const ConfigApkVersionList = () => {
    const [dataSource, setDataSource] = useState([]);
    const { pkgId } = useParams();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [versionId, setVersionId] = useState(0);
    const [opr, setOpr] = useState('add');
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
        });
    };

    useEffect(() => {
        getList();
    }, [pkgId]);

    const getList = () => {
        setDataLoading(true);
        MiniGameApi.getPkgVersionList({ limit: 300, pkg_id: Number(pkgId) })
            .then((res) => {
                if (res.code === 0) {
                    setDataSource(res.data);
                } else {
                    error('获取列表失败');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setDataLoading(false);
            });
    };

    const submit = () => {
        form.validateFields().then(async ({ version, channel, config }) => {
            setLoading(true);
            if (opr === 'add') {
                if (pkgId && pkgId.length > 0) {
                    setVisible(false);
                    const res = await MiniGameApi.createPkgVersion({
                        pkg_id: Number(pkgId),
                        version,
                        channel,
                        config,
                    });
                    if (res.code === 0) {
                        success('创建成功');
                        setVisible(false);
                        setLoading(false);
                        getList();
                    } else {
                        error('创建失败');
                        setLoading(false);
                    }
                }
            }
            if (opr === 'edit') {
                if (pkgId && pkgId.length > 0 && versionId > 0) {
                    const res = await MiniGameApi.updatePkgVersion({
                        id: Number(versionId),
                        channel,
                        config,
                    });
                    if (res.code === 0) {
                        success('创建成功');
                        setVisible(false);
                        setLoading(false);
                        getList();
                    } else {
                        error('创建失败');
                        setLoading(false);
                    }
                }
            }
        });
    };

    const handleAdd = () => {
        form.setFieldsValue({
            channel: '',
            version: '',
            config: '',
        });
        setOpr('add');
        setVisible(true);
    };

    const handleEdit = (e) => {
        form.setFieldsValue({
            channel: e.target.dataset.channel,
            config: e.target.dataset.config,
        });
        setOpr('edit');
        setVersionId(e.target.dataset.id);
        setVisible(true);
    };

    const gotoPkgList = () => {
        navigate('/config/apk-list');
    };

    const columns = [
        {
            title: '包名',
            dataIndex: 'pkg_name',
            key: 'pkg_name',
        },
        { title: 'Bundle包（', dataIndex: 'bundle', key: 'bundle' },
        { title: '版本号', dataIndex: 'version', key: 'version' },
        { title: '渠道', dataIndex: 'channel', key: 'channel' },
        {
            title: '配置/config',
            dataIndex: 'config',
            key: 'config',
            width: 200,
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <button
                        type="button"
                        onClick={handleEdit}
                        data-channel={record.channel}
                        data-config={record.config}
                        data-id={record.id}
                        className="ant-btn ant-btn-primary"
                    >
                        修改
                    </button>
                    <button
                        type="button"
                        // onClick={del}
                        data-id={record.id}
                        className="ant-btn ant-btn-primary ant-btn-dangerous"
                    >
                        删除
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <AdminLayout>
            {contextHolder}
            {/* 搜索栏 */}
            <div className="search-bar">{/* 搜索表单项... */}</div>

            {/* 新增按钮 */}
            <div
                className="add-button"
                style={{ display: 'flex', gap: '15px' }}
            >
                <Button
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={gotoPkgList}
                >
                    返回包列表
                </Button>
                <Button type="primary" onClick={handleAdd}>
                    添加version
                </Button>
            </div>
            <br />
            {/* 列表 */}
            <Table
                dataSource={dataSource}
                columns={columns}
                scroll={{ x: true }}
                loading={dataLoading}
                rowKey="version"
            />
            <Modal
                open={visible}
                title={opr === 'add' ? '添加版本' : '修改版本'}
                onCancel={() => setVisible(false)}
                onOk={submit}
                okText="确定"
                cancelText="取消"
                confirmLoading={loading}
            >
                <Form form={form}>
                    {opr === 'add' && (
                        <Form.Item
                            name="version"
                            label="版本号"
                            // rules={[
                            //     {
                            //         required: opr === 'add',
                            //         message: '请输入版本号',
                            //     },
                            // ]}
                        >
                            <Input />
                        </Form.Item>
                    )}

                    <Form.Item
                        name="channel"
                        label="渠道"
                        rules={[{ required: true, message: '请输入渠道' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="config"
                        label="配置"
                        rules={[{ required: true, message: '请输入配置' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </AdminLayout>
    );
};

export default ConfigApkVersionList;
