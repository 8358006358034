import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Modal, Form, Input, message } from 'antd';
import AdminLayout from '../layout/AdminLayout';
import { useNavigate } from 'react-router-dom';
import MiniGameApi from '../../components/MiniGameApi';

const ConfigApkList = () => {
    const [dataSource, setDataSource] = useState([]);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
        });
    };

    const error = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
        });
    };

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        setDataLoading(true);
        MiniGameApi.getPkgList({ limit: 300 })
            .then((res) => {
                if (res.code === 0) {
                    setDataSource(res.data);
                } else {
                    error('获取列表失败');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setDataLoading(false);
            });
    };

    const submit = () => {
        form.validateFields()
            .then(async ({ pkg_name, bundle }) => {
                setLoading(true);
                try {
                    const res = await MiniGameApi.createPkg({
                        pkg_name,
                        bundle,
                    });
                    if (res.code === 0) {
                        success('创建成功');
                        setVisible(false);
                        setLoading(false);
                        getList();
                    } else {
                        error('创建失败');
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                    throw new Error(err);
                }
            })
            .catch((error) => {
                setLoading(false);
                throw new Error(error);
            });
    };

    const handleAdd = () => {
        form.setFieldsValue({
            pkg_name: '',
            bundle: '',
        });
        setVisible(true);
    };

    const gotoVersions = (pkgId: number) => {
        navigate(`/config/apk-version-list/${pkgId}`);
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        {
            title: '包名（Package Name）',
            dataIndex: 'pkg_name',
            key: 'pkg_name',
        },
        {
            title: 'Bundle名（Bundle）',
            dataIndex: 'pkg_bundle',
            key: 'pkg_bundle',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <button
                        type="button"
                        onClick={() => {
                            gotoVersions(record.id);
                        }}
                        className="ant-btn ant-btn-primary"
                    >
                        查看该包version
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <AdminLayout>
            {contextHolder}
            {/* 搜索栏 */}
            <div className="search-bar">{/* 搜索表单项... */}</div>

            {/* 新增按钮 */}
            <div
                className="add-button"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button type="primary" onClick={handleAdd}>
                    添加
                </Button>
            </div>
            <br />
            {/* 列表 */}
            <Table
                dataSource={dataSource}
                columns={columns}
                loading={dataLoading}
                rowKey="id"
            />
            <Modal
                open={visible}
                title="新增"
                onCancel={() => setVisible(false)}
                onOk={submit}
                okText="确定"
                cancelText="取消"
                confirmLoading={loading}
            >
                <Form form={form}>
                    <Form.Item
                        name="pkg_name"
                        label="包名（package name）"
                        rules={[{ required: true, message: '请输入pkgName' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="bundle"
                        label="bundle名（pkgBundle）"
                        rules={[{ required: true, message: '请输入pkgBundle' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </AdminLayout>
    );
};

export default ConfigApkList;
