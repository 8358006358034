import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Modal, Form, Input } from 'antd';
import AdminLayout from './../layout/AdminLayout';
import { useNavigate, useParams } from 'react-router-dom';

import Api from './../../components/Api';

const ListPage = () => {
    const [dataSource, setDataSource] = useState([]);
    const [visible, setVisible] = useState(false);
    const { id } = useParams();
    const [apkId, setApkId] = useState(0);
    const [opr, setOpr] = useState('add');
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        Api.apkList({ groupid: id, page_size: 100 })
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    var apk_list = response.data.data;
                    for (var i = 0; i < apk_list.length; i++) {
                        if (apk_list[i].last_monitor_time > 0) {
                            const date = new Date(
                                apk_list[i].last_monitor_time * 1000
                            );
                            apk_list[i].last_monitor_time =
                                date.toLocaleString();
                        } else {
                            apk_list[i].last_monitor_time = '';
                        }
                    }
                    setDataSource(apk_list);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const submit = () => {
        form.validateFields()
            .then((values) => {
                if (opr === 'add') {
                    Api.apkAdd({
                        groupid: parseInt(id, 10),
                        code: values.code,
                        title: values.title,
                        country: values.country,
                        url: values.url,
                    })
                        .then((response) => {
                            if (response.data.code > 0) {
                                if (response.data.code === 10003) {
                                    navigate('/login');
                                } else {
                                    alert(response.data.msg);
                                }
                            } else {
                                setVisible(false);
                                form.resetFields();
                                getList();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else if (opr === 'edit') {
                    Api.apkEdit(apkId, {
                        code: values.code,
                        title: values.title,
                        country: values.country,
                        url: values.url,
                    })
                        .then((response) => {
                            if (response.data.code > 0) {
                                if (response.data.code === 10003) {
                                    navigate('/login');
                                } else {
                                    alert(response.data.msg);
                                }
                            } else {
                                setVisible(false);
                                form.resetFields();
                                getList();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            })
            .catch((error) => {});
    };

    const del = (event) => {
        var id = event.target.dataset.id;
        Api.apkDel(id)
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    getList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const back = (e) => {
        navigate('/group');
    };

    const handleAdd = () => {
        form.setFieldsValue({
            code: '',
            title: '',
            country: '',
            url: '',
        });
        setVisible(true);
        setOpr('add');
        setApkId(0);
    };

    const handleEdit = (e) => {
        form.setFieldsValue({
            code: e.target.dataset.code,
            title: e.target.dataset.title,
            country: e.target.dataset.country,
            url: e.target.dataset.url,
        });
        setVisible(true);
        setOpr('edit');
        setApkId(e.target.dataset.id);
    };

    const add = () => {
        navigate('/apk/add');
    };

    const edit = (event) => {
        var id = event.target.dataset.id;
        navigate('/apk/edit/' + id);
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: '编号', dataIndex: 'code', key: 'code' },
        { title: '国家', dataIndex: 'country', key: 'country' },
        { title: '名称', dataIndex: 'title', key: 'title' },
        { title: '地址', dataIndex: 'url', key: 'url' },
        {
            title: '最新监控时间',
            dataIndex: 'last_monitor_time',
            key: 'last_monitor_time',
        },
        {
            title: '最新监控状态',
            dataIndex: 'last_monitor_status',
            key: 'last_monitor_status',
            render: (text, item) => {
                var msg = '';
                switch (item.last_monitor_status) {
                    case 0:
                        msg = '未监听';
                        break;
                    case 1:
                        msg = '正常';
                        break;
                    default:
                        msg = '异常';
                        break;
                }
                return msg;
            },
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <button
                        type="primary"
                        onClick={handleEdit}
                        data-code={record.code}
                        data-url={record.url}
                        data-title={record.title}
                        data-country={record.country}
                        data-id={record.id}
                        className="ant-btn ant-btn-primary"
                        ant-click-animating-without-extra-node="false"
                    >
                        修改
                    </button>
                    <button
                        type="primary"
                        onClick={del}
                        data-id={record.id}
                        className="ant-btn ant-btn-primary ant-btn-dangerous"
                    >
                        删除
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <AdminLayout>
            {/* 搜索栏 */}
            <div className="search-bar">{/* 搜索表单项... */}</div>

            {/* 新增按钮 */}
            <div
                className="add-button"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button type="primary" onClick={handleAdd}>
                    添加
                </Button>
                <Button type="" onClick={back}>
                    返回
                </Button>
            </div>
            <br />
            {/* 列表 */}
            <Table dataSource={dataSource} columns={columns} />
            <Modal
                open={visible}
                title="监控包"
                onCancel={() => setVisible(false)}
                onOk={submit}
                okText="确定"
                cancelText="取消"
            >
                <Form form={form}>
                    <Form.Item
                        name="code"
                        label="编号"
                        rules={[{ required: true, message: '请输入编号' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="title"
                        label="标题"
                        rules={[{ required: true, message: '请输入标题' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="country"
                        label="国家"
                        rules={[{ required: true, message: '请输入国家' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="url"
                        label="网址"
                        rules={[{ required: true, message: '请输入网址' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </AdminLayout>
    );
};

export default ListPage;
