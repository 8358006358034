import React from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './login.css';
import Api from './../../components/Api';
import { setAuthToken } from '../../components/Request';

const Login = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const onFinish = (values) => {
        Api.login({ account: values.username, password: values.password })
            .then((response) => {
                if (response.data.code === 0) {
                    // success login
                    setAuthToken(response.data.data);
                    navigate('/group');
                }
            })
            .catch((error) => {
                console.error(error);
                form.setFields([
                    {
                        name: 'password',
                        errors: ['账号或密码错误'],
                    },
                ]);
            });

        // axios.post('http://127.0.0.1:8082/api/login',{account:values.username,password:values.password})
        // .then(response => {
        //      if(response.data.code > 0){
        //         form.setFields([
        //           {
        //             name: 'password',
        //             errors: ['账号或密码错误'],
        //           },
        //       ]);
        //      }else{
        //       localStorage.setItem('token', response.data.token);
        //       navigate('/apk');
        //      }
        // })
    };

    return (
        <div className="login-page">
            <Form onFinish={onFinish} className="login-form" form={form}>
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: '请输入您的账号!' }]}
                >
                    <Input placeholder="手机号或邮箱" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入您的密码!' }]}
                >
                    <Input.Password placeholder="密码" />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
