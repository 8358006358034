import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Modal } from 'antd';
import AdminLayout from './../layout/AdminLayout';
import { useNavigate } from 'react-router-dom';
import Api from './../../components/Api';

const ListPage = () => {
    const [dataSource, setDataSource] = useState([]);
    const [delId, setDelId] = useState(0);
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    const { Meta } = Card;

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        Api.groupList()
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    setDataSource(response.data.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const del = (event) => {
        var id = event.target.dataset.id;
        setDelId(id);
        setVisible(true);
    };

    const add = () => {
        navigate('/group/add');
    };

    const apk = (event) => {
        var id = event.target.dataset.id;

        navigate('/group/' + id + '/apk');
    };

    const setting = (event) => {
        var id = event.target.dataset.id;

        navigate('/group/setting/' + id);
    };

    const handleOk = () => {
        Api.groupDel(delId)
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    getList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <AdminLayout>
            {/* 搜索栏 */}
            <div className="search-bar">{/* 搜索表单项... */}</div>

            {/* 新增按钮 */}
            <div className="add-button">
                <Button type="primary" onClick={add}>
                    添加
                </Button>
            </div>
            <br />
            {/* 列表 */}
            {/* <Table dataSource={dataSource} columns={columns}/> */}

            <div>
                <Row gutter={[16, 16]}>
                    {dataSource.map((item, index) => (
                        <Col span={8} key={index}>
                            <Card
                                title={item.name}
                                actions={[
                                    <a
                                        href="#"
                                        onClick={setting}
                                        data-id={item.id}
                                    >
                                        推送渠道
                                    </a>,
                                    <a href="#" onClick={apk} data-id={item.id}>
                                        监控列表
                                    </a>,
                                    <a href="#" onClick={del} data-id={item.id}>
                                        删除
                                    </a>,
                                ]}
                            >
                                <Meta
                                    title="简介"
                                    description={item.describe}
                                />
                                <br />

                                <Meta
                                    title="Webhook"
                                    description={
                                        'https://apk-admin.infigaming.site/api/apk/subscribe?access_token=' +
                                        item.access_token
                                    }
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            <Modal
                title="操作提示"
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="确定"
                cancelText="取消"
            >
                <p>
                    在进行删除之前，请确保您已经仔细考虑，并且了解删除操作的后果
                </p>
                <p>确认要执行删除操作吗？</p>
            </Modal>
        </AdminLayout>
    );
};

export default ListPage;
