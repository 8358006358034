import { request } from "./Request";

export default {
    //登录
    login: function (param) {
        return request({
            url: "/api/login",
            method: "post",
            data: param
        });
    },

    redirectList :function (param) {
        return request({
            url: "/api/redirect",
            method: "get",
            params: param
        });
    },

    redirectDel: function (id) {
        return request({
            url: "/api/redirect/" + id,
            method: "delete",
        });
    },

    redirectAdd: function (param) {
        return request({
            url: "/api/redirect",
            method: "post",
            data: param
        });
    },

    redirectEdit: function (id, param) {
        return request({
            url: "/api/redirect/" + id,
            method: "put",
            data: param
        });
    },
    redirect: function (id) {
        return request({
            url: "/api/redirect/" + id,
            method: "get",
        });
    },

    redirectCode: function () {
        return request({
            url: "/api/redirect/code",
            method: "get",
        });
    },

    apkList: function (param) {
        return request({
            url: "/api/apk",
            method: "get",
            params: param
        });
    },

    apkDel: function (id) {
        return request({
            url: "/api/apk/" + id,
            method: "delete",
        });
    },

    apkAdd: function (param) {
        return request({
            url: "/api/apk",
            method: "post",
            data: param
        });
    },

    apkEdit: function (id, param) {
        return request({
            url: "/api/apk/" + id,
            method: "put",
            data: param
        });
    },
    apk: function (id) {
        return request({
            url: "/api/apk/" + id,
            method: "get",
        });
    },

    groupList: function (param) {
        return request({
            url: "/api/group",
            method: "get",
            data: param
        });
    },

    group: function (id, param) {
        return request({
            url: "/api/group/" + id,
            method: "get",
            data: param
        });
    },

    groupAdd: function (param) {
        return request({
            url: "/api/group",
            method: "post",
            data: param
        });
    },

    groupEdit: function (param) {
        return request({
            url: "/api/group",
            method: "post",
            data: param
        });
    },

    groupDel: function (id) {
        return request({
            url: "/api/group/" + id,
            method: "delete",
        });
    },

    notifictionList:function(param){
        return request({
            url: "/api/notification",
            method: "get",
            params:param
        });
    },

    notifictionAdd:function(param){
        return request({
            url: "/api/notification",
            method: "post",
            params:param
        });
    },

    notifictionEdit:function(id,param){
        return request({
            url: "/api/notification/"+id,
            method: "put",
            params:param
        });
    },

    notifictionDel:function(id){
        return request({
            url: "/api/notification/"+id,
            method: "delete",
        });
    },

}
