import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Index from './pages/index/Index';
import Login from '../login/Login';
import ApkList from '../apk/List';
import ApkEdit from '../apk/Edit';
import ApkAdd from '../apk/Add';
import GroupList from '../group/List';
import GroupAdd from '../group/Add';
import GroupSetting from '../group/Setting';
import RedirectList from '../redirect/List';
import ConfigApkList from '../config-apk/List.tsx';
import ConfigApkVersionList from '../config-apk/VersionList.tsx';

function Index() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path='/apk' element={<ApkList />} /> */}
                <Route path="/apk/edit/:id" element={<ApkEdit />} />
                <Route path="/apk/add" element={<ApkAdd />} />

                <Route path="/group" element={<GroupList />} />
                <Route path="/group/add" element={<GroupAdd />} />
                <Route path="/group/setting/:id" element={<GroupSetting />} />
                <Route path="/group/:id/apk" element={<ApkList />} />

                <Route path="/redirect" element={<RedirectList />} />

                <Route path="/config/apk-list" element={<ConfigApkList />} />
                <Route
                    path="/config/apk-version-list/:pkgId"
                    element={<ConfigApkVersionList />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default Index;
