import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Modal, Table, Space, Select } from 'antd';
import 'antd/dist/antd.min.css';
import AdminLayout from './../layout/AdminLayout';
import Api from './../../components/Api';

const Setting = () => {
    const { Option } = Select;
    const navigate = useNavigate();
    const [type, setType] = useState('1');
    const [name, setName] = useState('');
    const [nid, setNid] = useState(0);
    const [opr, setOpr] = useState('add');
    const [webhook, setWebhook] = useState('');
    const [chatId, setChatId] = useState('');
    const [token, setToken] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const { id } = useParams();

    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        getList();
    }, []);

    const submit = () => {
        form.validateFields()
            .then((values) => {
                var config = '';
                if (values.type === '1' || values.type === '3') {
                    config = JSON.stringify({
                        webhook: values.webhook,
                    });
                } else if (values.type === '2') {
                    config = JSON.stringify({
                        chat_id: values.chat_id,
                        token: values.token,
                    });
                }

                if (opr === 'add') {
                    Api.notifictionAdd({
                        name: values.name,
                        type: values.type,
                        config: config,
                        groupid: id,
                    })
                        .then((response) => {
                            if (response.data.code > 0) {
                                if (response.data.code === 10003) {
                                    navigate('/login');
                                } else {
                                    alert(response.data.msg);
                                }
                            } else {
                                setVisible(false);
                                form.resetFields();
                                getList();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else if (opr === 'edit') {
                    Api.notifictionEdit(nid, {
                        name: values.name,
                        type: values.type,
                        config: config,
                        groupid: id,
                    })
                        .then((response) => {
                            if (response.data.code > 0) {
                                if (response.data.code === 10003) {
                                    navigate('/login');
                                } else {
                                    alert(response.data.msg);
                                }
                            } else {
                                setVisible(false);
                                form.resetFields();
                                getList();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            })
            .catch((error) => {});
    };

    const getList = () => {
        Api.notifictionList({ groupid: id })
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    }
                } else {
                    setDataSource(response.data.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleAdd = () => {
        setType('');
        form.setFieldsValue({
            name: '',
            chat_id: '',
            type: '',
            token: '',
            webhook: '',
        });

        setVisible(true);
        setOpr('add');
        setNid(0);
    };

    const handleEdit = (e) => {
        var config = JSON.parse(e.target.dataset.config);

        form.setFieldsValue({
            name: e.target.dataset.name,
            chat_id: config.chat_id,
            type: e.target.dataset.type,
            token: config.token,
            webhook: config.webhook,
        });
        setType(e.target.dataset.type);
        setVisible(true);
        setNid(e.target.dataset.id);
        setOpr('edit');
    };

    const typeChange = (value) => {
        setType(value);
    };

    const del = (e) => {
        var id = e.target.dataset.id;
        Api.notifictionDel(id)
            .then((response) => {
                if (response.data.code > 0) {
                    if (response.data.code === 10003) {
                        navigate('/login');
                    } else {
                        alert(response.data.msg);
                    }
                } else {
                    getList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const back = (e) => {
        navigate('/group');
    };

    const columns = [
        { title: '名称', dataIndex: 'name', key: 'name' },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            render: (text, item) => {
                var msg = '';
                switch (item.type) {
                    case 1:
                        msg = '钉钉';
                        break;
                    case 2:
                        msg = 'telegram';
                        break;
                    case 3:
                        msg = 'discord';
                        break;
                    default:
                        msg = 'telegram';
                        break;
                }
                return msg;
            },
        },
        { title: '配置', dataIndex: 'config', key: 'config' },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" key={record.id}>
                    <button
                        data-id={record.id}
                        data-name={record.name}
                        data-type={record.type}
                        data-config={record.config}
                        type="button"
                        onClick={handleEdit}
                        className="ant-btn ant-btn-primary"
                        ant-click-animating-without-extra-node="false"
                    >
                        修 改
                    </button>
                    <button
                        data-id={record.id}
                        onClick={del}
                        type="button"
                        className="ant-btn ant-btn-primary ant-btn-dangerous"
                    >
                        删 除
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <AdminLayout>
            <div
                className="add-button"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button type="primary" onClick={handleAdd}>
                    添加
                </Button>
                <Button type="" onClick={back}>
                    返回
                </Button>
            </div>
            <br />
            <Table dataSource={dataSource} columns={columns} />

            <Modal
                open={visible}
                title="推送方式"
                onCancel={() => setVisible(false)}
                onOk={submit}
                okText="确定"
                cancelText="取消"
            >
                <Form form={form}>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="推送类型"
                        name="type"
                        rules={[{ required: true, message: '请选择类型' }]}
                    >
                        <Select
                            value={type + ''}
                            style={{ width: 120, marginRight: 8 }}
                            onChange={(value) => typeChange(value)}
                        >
                            <Option value="1">钉钉</Option>
                            <Option value="2">Telegram</Option>
                            <Option value="3">Discord</Option>
                        </Select>
                    </Form.Item>

                    {type === '1' && (
                        <Form.Item
                            name="webhook"
                            label="webhook"
                            rules={[
                                { required: true, message: '请输入webhook' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    )}

                    {type === '2' && (
                        <>
                            <Form.Item
                                name="chat_id"
                                label="chat id"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入chat id',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="token"
                                label="token"
                                rules={[
                                    { required: true, message: '请输入token' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    )}

                    {type === '3' && (
                        <Form.Item
                            name="webhook"
                            label="webhook"
                            rules={[
                                { required: true, message: '请输入webhook' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </AdminLayout>
    );
};

export default Setting;
